import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "animate.css";
import polyhouse_vs_open_field_farming_img from "../../Assets/polyhouse_vs_open_field_farming_img.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

function Polyhouse_vs_Open_Field_Farming() {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container fluid>
      <div className="d-flex justify-content-center">
        <img
          src={polyhouse_vs_open_field_farming_img}
          className={`card-img-top img-fluid rounded animate__animated animate__fadeInDown ${
            showImage ? "show" : ""
          }`}
          style={{
            maxWidth: "90%",
            width: "100%",
            height: "auto",
            marginTop: "20px",
          }}
          alt="polyhouse farming"
        />
      </div>

      <Row className="mx-2 mx-md-5 my-4">
        <Col
          className="p-3 text-justify"
          sm={12}
          md={9}
          style={{
            fontSize: "1rem",
            lineHeight: "1.6",
            color: "#333",
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
          }}
        >
          <div style={{ padding: "20px" }}>
            <h1 className="text-center pb-3">
              Polyhouse vs. Open Field Farming: Which Is More Profitable?
            </h1>
            <p>
              Agriculture has evolved significantly over the years, introducing
              modern farming techniques that enhance productivity and
              profitability. One of the most debated topics among farmers today
              is the choice between polyhouse farming and open field farming.
              While both methods have their own merits, it is essential to
              understand their differences, advantages, and disadvantages to
              make an informed decision.
            </p>
            <h4>What is Polyhouse Farming?</h4>
            <p>
              Polyhouse farming is a method of growing crops inside a structure
              made of transparent material like polyethylene. It provides a
              controlled environment that helps maintain optimal temperature,
              humidity, and light for plant growth. Polyhouses can be either
              naturally ventilated or climate-controlled, depending on the
              farmer’s budget and requirements.
            </p>
            <h4>Advantages of Polyhouse Farming:</h4>
            <p>
              <ol>
                <li>
                  <span className="fw-bold">Higher Crop Yield:</span> Due to
                  controlled environmental conditions.
                </li>
                <li>
                  <span className="fw-bold">Quality Produce:</span> Minimal
                  exposure to pests and diseases.
                </li>
                <li>
                  <span className="fw-bold">Year-Round Production:</span>{" "}
                  Independent of seasonal changes.
                </li>
                <li>
                  <span className="fw-bold">
                    Efficient Resource Utilization:
                  </span>{" "}
                  Optimal use of water and fertilizers.
                </li>
                <li>
                  <span className="fw-bold">
                    Protection from Adverse Weather:
                  </span>{" "}
                  Crops are safeguarded against heavy rains, extreme
                  temperatures, and strong winds.
                </li>
              </ol>
            </p>

            <h4>Disadvantages of Polyhouse Farming:</h4>
            <p>
              <ol>
                <li>
                  <span className="fw-bold">High Initial Investment:</span>{" "}
                  Setting up a polyhouse can be expensive.
                </li>
                <li>
                  <span className="fw-bold">Maintenance Costs:</span> Regular
                  monitoring and upkeep are necessary.
                </li>
                <li>
                  <span className="fw-bold">Technical Expertise Required:</span>{" "}
                  Knowledge of temperature and humidity control is essential.
                </li>
              </ol>
            </p>

            <h4>What is Open Field Farming?</h4>
            <p>
              Open field farming is the traditional method where crops are grown
              directly on land without any protective structure. It relies on
              natural weather conditions for crop growth and yield.
              <ol>
                <h4>Advantages of Open Field Farming</h4>
                <li>
                  <span className="fw-bold">Low Initial Investment:</span>{" "}
                  Minimal setup costs as it uses natural resources.
                </li>
                <li>
                  <span className="fw-bold">Easier to Implement:</span> Suitable
                  for farmers with limited capital.
                </li>
                <li>
                  <span className="fw-bold">Natural Growth Environment:</span>{" "}
                  Plants grow under natural climatic conditions.
                </li>
              </ol>
            </p>

            <h4>Disadvantages of Open Field Farming:</h4>

            <p>
              <ol>
                <li>
                  <span className="fw-bold">Low Yield and Quality:</span> Prone
                  to pest attacks and inconsistent weather.
                </li>
                <li>
                  <span className="fw-bold">Unpredictable Output:</span> Yields
                  vary greatly due to weather fluctuations.
                </li>
                <li>
                  <span className="fw-bold">High Water Consumption:</span>{" "}
                  Inefficient use of resources can lead to wastage.
                </li>
              </ol>
            </p>

            <h4>Crop Yield Comparison:</h4>
            <p>
              In polyhouse farming, crops are grown in a controlled environment,
              allowing for higher productivity per unit area. For instance,
              crops like tomatoes and bell peppers yield almost 3 to 4 times
              more in a polyhouse compared to open fields. In contrast, open
              field farming often suffers from lower yields due to unfavorable
              weather conditions and pest infestations.
            </p>
            <h4>Quality of Produce:</h4>
            <p>
              Polyhouse farming ensures better quality produce as plants are
              protected from dust, pests, and diseases. The controlled
              environment minimizes contamination and results in uniform growth.
              Open field farming, however, often produces inconsistent quality
              due to varying weather and pest issues.
            </p>
            <h4>Comparative Analysis: Polyhouse vs. Open Field Farming</h4>
            <p>
              To better understand the differences between polyhouse and open
              field farming, let's take a look at the following comparative
              table:
            </p>
            <p>
              <div className="table-responsive">
                <table className="table table-bordered border-dark">
                  <thead className="thead-dark">
                    <tr>
                      <th>Parameter</th>
                      <th>Polyhouse Farming</th>
                      <th>Open Field Farming</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Initial Investment</td>
                      <td>High, due to infrastructure and technology</td>
                      <td>Low, minimal setup costs</td>
                    </tr>
                    <tr>
                      <td>Crop Yield</td>
                      <td>
                        High, with controlled environment and better growth
                      </td>
                      <td>Low to moderate, influenced by weather</td>
                    </tr>
                    <tr>
                      <td>Quality of Produce</td>
                      <td>
                        Superior, uniform growth with minimal pest and disease
                        risk
                      </td>
                      <td>Variable, affected by pests and weather</td>
                    </tr>
                    <tr>
                      <td>Water Usage</td>
                      <td>Efficient, with controlled irrigation systems</td>
                      <td>High, due to evaporation and runoff</td>
                    </tr>
                    <tr>
                      <td>Maintenance Requirement</td>
                      <td>
                        Regular maintenance of climate control and structure
                      </td>
                      <td>Minimal, mainly focused on soil management</td>
                    </tr>
                    <tr>
                      <td>Profitability</td>
                      <td>High, long-term gains with quality produce</td>
                      <td>Low to moderate, heavily dependent on climate</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </p>

            <p>
              When it comes to profitability, polyhouse farming clearly
              outshines open field farming. While the initial investment is
              higher, the increased yield, better quality produce, and
              consistent income make it a worthwhile option. Open field farming,
              on the other hand, remains suitable for low-budget operations but
              comes with greater risks and variable returns.
            </p>
            <p>
              If you are looking to boost your agricultural business, consider
              investing in polyhouse farming for long-term profitability and
              sustainable production.
            </p>
            <p>
              <h5>Contact Us:</h5>
              <div className="d-flex align-items-center mb-3">
                <FontAwesomeIcon icon={faPhone} className="me-2 text-success" />
                <span>
                  Phone:{" "}
                  <a
                    href="tel:+919960991166"
                    className="text-primary text-decoration-none"
                  >
                    +91-9960-991-166
                  </a>
                </span>
              </div>
              <div className="d-flex align-items-center mb-3">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="me-2 text-success"
                />
                <span>
                  Email:{" "}
                  <a
                    href="mailto:info@royalgreenhouse.co.in"
                    target="_blank"
                    className="text-primary text-decoration-none"
                  >
                    info@royalgreenhouse.co.in
                  </a>
                </span>
              </div>
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={faGlobe} className="me-2 text-success" />
                <span>
                  Website:{" "}
                  <a
                    href="http://www.royalgreenhouse.co.in/"
                    className="text-primary text-decoration-none"
                  >
                    www.royalgreenhouse.co.in
                  </a>
                </span>
              </div>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Polyhouse_vs_Open_Field_Farming;
